import React from 'react';
import styled from 'styled-components';
import Paragraph from './../paragraph/paragraph'
import { navigate } from "gatsby"

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 18px 10px;
  }

  @media {
    flex-flow: column nowrap;
    background-color: #22422b;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    marginTop:0;
    right: 0;
    height: 100vh;
    z-index:19;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <li>
        <Paragraph
          onClick={() =>
            navigate("/", { replace: false })
          }
          style={{ color: "white" }}
        >


          <b>Home</b>


        </Paragraph>
      </li>
      <li>
        <Paragraph
          onClick={() =>
            navigate("/projects", { replace: false })
          }
          style={{ color: "white" }}
        >


          <b>Projects</b>


        </Paragraph>

      </li>
      <li>
        <Paragraph
          onClick={() =>
            navigate("/projects/forests", { replace: false })
          }
          style={{ color: "white" }}
        >


          <b>Offset Overview</b>


        </Paragraph>
      </li>
      <li>
        <Paragraph
          onClick={() =>
            navigate("/projects/ourstory", { replace: false })
          }
          style={{ color: "white" }}
        >


          <b>Our Story</b>


        </Paragraph>
      </li>
    </Ul>
  )
}

export default RightNav
