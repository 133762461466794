import React, { Component } from "react"
import Paragraph from "../paragraph/paragraph"

function Footer() {
  return (
    <div
      style={{
        backgroundColor: "#22422B",
        height: 100,
        marginTop: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: "white",
          alignSelf: "center",
        }}
      >
        © Carbon Down 2021
      </p>
    </div>
  )
}

export default Footer
