import React from "react"
import { propTypes } from "react-bootstrap/esm/Image"
import Paragraph from "./../paragraph/paragraph"
import Header1 from "./../header1/header1"
import { navigate } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Nav from "./Burger"
import Hidden from "@material-ui/core/Hidden"

export default function Navbar(props) {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#22422B",
          height: '10vh',
          justifyItems:'center',
          alignItems:'center',
          paddingLeft: 40,
        }}
      >
        <Hidden smDown >
          <Grid container>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                height:'10vh',
                justifyItems:'center',
                alignItems:'center',
              }}
            >
              <img
                src={require("./../../assets/WhiteLogo.png")}
                width={'300vh'}
                style={{alignSelf:'center'}}
                onClick={() => navigate("/")}
              />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={6}
              lg={6}
              xl={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height:'10vh',
                justifyItems:'center',
                alignItems:'center',
              }}
            >
              <Paragraph
                onClick={() => navigate("/projects/", { replace: false })}
                style={{ color: "white"}}
              >
                {props.selected == "Projects" ? (
                  <u>
                    <b>Projects</b>
                  </u>
                ) : (
                  `Projects`
                )}
              </Paragraph>
              <Paragraph
                onClick={() =>
                  navigate("/projects/forests", { replace: false })
                }
                style={{ color: "white" }}
              >
                {props.selected == "forests" ? (
                  <u>
                    <b>Offset Overview</b>
                  </u>
                ) : (
                  `Offset Overview`
                )}
              </Paragraph>
              <Paragraph
                onClick={() =>
                  navigate("/projects/ourstory", { replace: false })
                }
                style={{ color: "white" }}
              >
                {props.selected == "ourstory" ? (
                  <u>
                    <b>Our Story</b>
                  </u>
                ) : (
                  `Our Story`
                )}
              </Paragraph>
            </Grid>

            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container>
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              style={{
                display:'flex',
                height:'10vh',
                width:'100%',
              }}
            >
              <Header1 style={{paddingTop:'0', margin:0, alignSelf:'center',justifySelf:'center', fontSize:'6vw', color:'white'}}>
                Carbon Down
              </Header1>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              style={{
                display:'flex',
                alignSelf: "center",
                justifyItems:'center',
                alignItems:'center',
                height:'10vh',
                paddingBottom:27,
              }}
            >
              <Nav />
            </Grid>
          </Grid>
        </Hidden>
      </div>
    </div>
  )
}
